.quickSearchWidget {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media print, screen and (min-width: 64em) {
        height: 94vh;
    }
}

.text {
    word-break: break-word;
    @media print, screen and (min-width: 40em) {
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media print, screen and (min-width: 64em) {
        flex: 0 0 30%;
        max-width: 30%;
    }
    @media print, screen and (min-width: 85em) {
        flex: 0 0 40%;
        max-width: 40%;
    }
}

.headline {
    margin: 0 0 .5rem 0;
    font-size: 1.75rem;
    line-height: 100%;
    font-weight: 700;
    @media print, screen and (min-width: 40em) {
        font-size: 3rem;
    }
}

.cities {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 4rem 0;

    @media print, screen and (min-width: 40em) {
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media print, screen and (min-width: 64em) {
        flex: 0 0 70%;
        max-width: 70%;
        margin: 0;
    }
    @media print, screen and (min-width: 85em) {
        flex: 0 0 60%;
        max-width: 60%;
    }
}

.circleContent {
    display: flex;
    justify-content: center;
    align-content: center;
    flex: 0 0 50%;
    max-width: 50%;
    cursor: pointer;

    @media print, screen and (min-width: 40em)  {
    }

    @media print, screen and (min-width: 64em)  {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    @media print, screen and (min-width: 85em)  {
    }

    a {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20vh;
        height: 20vh;
        margin: .5rem;
        text-align: center;
        border-width: 5px;
        border-style: solid;
        border-radius: 50%;
        color: #000;
        border-color: #df1051;
        background-color: #fff;
        transform: scale3d(1, 1, 1);
        transform-style: preserve-3d;
        text-decoration: none;

        &:hover {
            background-color: #440deb;
            border-color: #440deb;
            color: #fff;
            text-decoration: none;
        }

        @media print, screen and (min-width: 40em)  {
            width: 15vh;
            height: 15vh;
        }

        @media print, screen and (min-width: 64em)  {
            width: 17vw;
            height: 17vw;
        }

        @media print, screen and (min-width: 75em)  {
            width: 15vw;
            height: 15vw;
        }

        @media print, screen and (min-width: 130em)  {
            width: 12vw;
            height: 12vw;
        }

        @media print, screen and (min-width: 150em)  {
            width: 10vw;
            height: 10vw;
        }
    }
}

.city {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
}

.jobs {
    font-size: .875rem;
    line-height: 1;
    font-style: normal;
}


.gallery {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    height: 75%;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    @media print, screen and (min-width: 40em)  {
        width: 60%;
        height: 100%;
        margin: 0 0 0 auto;
    }

    @media print, screen and (min-width: 64em)  {
        width: 70%;
    }
}

.img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 90%;
    max-width: none;
    object-fit: cover;
    @media print, screen and (min-width: 40em) {

    }
    @media print, screen and (min-width: 64em) {
        height: auto;
        width: 100%;
        max-width: 100%;
    }
}